import { Person } from '../interfaces/people.interface';

export const keyPeople: Person[] = [
  {
    pic: 'ms@1.jpg',
    name: 'Manjinder Sandhu',
    position: 'CEO & Founder',
    bio: `Manjinder is a Professor of Population Health and Data Sciences at Imperial College London. He trained at King's College London, the London School of Hygiene and Tropical Medicine, and the University of Cambridge. He was also senior group leader at the Wellcome Sanger Institute between 2009 and 2019.\n\nHis academic research focuses on creating and interpreting health and genetic data and integrating technological advances at the population health level. As Omnigen's Founder, his work now centres on integrating real-world data, including electronic health records, and genomic resources in Europe, the Middle East, Asia and Africa.`,
  },
  {
    pic: 'lb@1.jpg',
    name: 'Louise Barr',
    position: 'Strategic Partnerships Lead',
    bio: `Louise has more than ten years' experience in commercial and academic research. She completed her PhD in Epidemiology at the University of Cambridge and a Masters in Public Health from London School of Hygiene and Tropical Medicine. Her research has primarily focussed on large-scale population health research in the African region. As part of this work, she has also been involved in capacity-building initiatives such as the African Partnership for Chronic Disease Research.\n\nAt Omnigen, she is responsible for managing strategic partnerships with clients and vendors to ensure the delivery of the Discover Me programme.`,
  },
  {
    pic: 'mc.jpg',
    name: 'Mark Cullen',
    position: 'Head of Product & Technology',
    bio: `Mark has over 20 years of experience in user-centred design and development in various industries, from finance, logistics and e-commerce. Mark prides himself on harmonizing cutting-edge technology with human-centric design principles, resulting in innovative and intuitive digital products.\n\nAt Omnigen, Mark is responsible for defining the product strategy, setting the product vision, and ensuring the successful execution of product initiatives, including customer insights where possible to drive successful outcomes.`,
  },
];

export default { keyPeople };
